import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const EcommerceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">E-Commerce Operations</span>
                            <h3>Digital Commerce</h3>
                            <p>Our approach is to focus on designing omnichannel customer journeys based on market developments, company strategy and customer data.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Offer </h3>
                                        <ul>
                                            <li>UI/UX Design</li>
                                            <li>Modular Content Management</li>
                                            <li>Front-End Development</li>
                                            <li>Back-end Development</li>
                                            <li>DevOps</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>The foundation for our strategy is to understand how customers are interacting with your business across channels. We can help you to consolidate data across marketing, sales, customer support, product into a single view of the customer.</p>
                            <h3>E-Commerce Solutions</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Headless eCommerce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Adobe Magento 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Shopify eCommerce
                                    </div>
                                </div>
            
                            </div>

                            <h3>Technologies</h3>
                            <ul className="technologies-features">
                                <li><span>VueJS</span></li>
                                <li><span>ReactJS</span></li>
                                <li><span>GatsbyJS</span></li>
                                <li><span>NextJS</span></li>
                                <li><span>PHP</span></li>
                                <li><span>SQL</span></li>
                                <li><span>AWS</span></li>
                                <li><span>Segment</span></li>
                                <li><span>Snowflake</span></li>
                            </ul>
                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcommerceDetailsContent